<template>
  <div class="editar_eliminar_cont" >
    <div class="editar_eliminar_cont_" v-if="type == 'editar'">
      <div v-if="formato == 'icon'">
        <img :src="iconEditar" alt="" :style="style_img" />
      </div>
      <div v-if="formato == 'text'" class="editar_eliminar_cont_text" :style="style_text">
        {{ t("app.destinatario_detalle.editar") }}
      </div>
      <div v-if="formato == 'ambos'" class="editar_eliminar_doble">
        <div class="editar_eliminar_cont_text" :style="style_text">
          {{ t("app.destinatario_detalle.editar") }}
        </div>
        <img :src="iconEditar" alt="" :style="style_img" />
      </div>
    </div>
    <div class="editar_eliminar_cont_" v-if="type == 'eliminar'">
      <div v-if="formato == 'icon'">
        <img :src="iconBasurero" alt="" :style="style_img" />
      </div>
      <div v-if="formato == 'text'" :style="style_text">
        {{ t("app.destinatario_detalle.eliminar") }}
      </div>
      <div v-if="formato == 'ambos'" class="editar_eliminar_doble">
        <div class="editar_eliminar_cont_text" :style="style_text">
          {{ t("app.destinatario_detalle.eliminar") }}
        </div>
        <img :src="iconBasurero" alt="" :style="style_img" />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  props: {
    type: String,
    formato: String,
    ancho_img: Number,
    font_size_text: Number,
  },
  data() {
    return {
      iconEditar: require("../../assets/imgs/destinatarios/iconEditar.svg"),
      iconBasurero: require("../../assets/imgs/destinatarios/iconBasurero.svg"),
    };
  },
  computed: {
    style_img() {
      return {
        width: `${this.ancho_img}rem`,
      };
    },
    style_text(){
      return {
        ['font-size']:`${this.font_size_text}px`,
        color: this.type == "editar" ? '#614ad3' : '#801B1C'
      }
    }
  },
};
</script>

<style>
.editar_eliminar_cont {
  width: 100%;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
}
.editar_eliminar_cont_text {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.857033px;
  text-decoration-line: underline;
  padding-right: 0.5rem;
}
.editar_eliminar_doble{
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.editar_eliminar_cont_ img{
  width: 0.4rem;
}
</style>