<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="myHeader_vertical" ref="myHeader_misDatos">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerBack @back="backLocal" />
          </div>
        </div>

        <div class="misDatosTitle title title_margin_top">
          {{ t("app.mis_datos") }}
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_misDatos"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        ref="contenido_misDatos"
        class="contenido centrado"
        :style="contenido_misDatos"
        v-show="cont_visible"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="misDatosBody">
            <div
              class="misDatosRow"
              v-for="(item, w) in remitenteDatos"
              :key="w"
            >
              <div
                class="misDatosItem text_basico f_w_600"
                :class="w == 0 ? 'misDatosItemUno' : ''"
              >
                {{ t(`variables.reglasFormulario.Remitente_${item[0]}`) }}
                <div
                  v-if="w == 0"
                  class="misDatosItemUnoText text_basico f_w_500"
                  @click="callUpdateRemitenteForm"
                >
                  <!-- {{ t("app.comunes.btns.editar") }} -->
                  <editar_eliminar
                    type="editar"
                    formato="icon"
                    ancho_img="1.2"
                    font_size_text="10"
                  />
                </div>
              </div>
              <div class="misDatosData text_basico f_w_400">
                {{ item[1] }}
              </div>
              <!-- <div>
                  <div class="misDatosItem"></div>
                  <div class="misDatosData"></div>
                </div> -->
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_misDatos"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer misDatos_footer" ref="footer_misDatos"></div>
    </div>
  </screen_width_cont>
</template>

<script>
import headerBack from "../components/generales/headerBack.vue";
import { menuController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import { mapMutations, mapState } from "vuex";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import editar_eliminar from "../components/generales/editar_eliminar.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },

  name: "misDatos",
  components: {
    headerBack,
    blurComp,
    screen_width_cont,
    editar_eliminar,
  },
  data() {
    return {
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_misDatos: {},
      blur_sup_misDatos: {},
      blur_inf_misDatos: {},
    };
  },
  computed: {
    ...mapState([
      "menuSpDesde",
      "remitente",
      "configuracion",
      "desde",
      "heigthScreen",
      "nav_stack",
      "widthScreen",
      "plataforma",
    ]),

    remitenteDatos() {
      let aux = [];
      if (
        this.remitente &&
        this.configuracion &&
        this.configuracion.reglasFormularioRemitente
      ) {
        this.configuracion.reglasFormularioRemitente.forEach((element) => {
          if (this.remitente[element[4]]) {
            let aux2 = "";
            if (element[7] == "text" || element[7] == "radioBtns") {
              if (element[4] == "pep") {
                aux2 = this.t(
                  `variables.${element[4]}.${this.remitente[element[4]]}`
                );
              } else {
                aux2 = this.remitente[element[4]];
              }
            } else if (element[7] == "tel") {
              aux2 = this.remitente[element[4]].replace("-", " ");
            } else if (element[7] == "selectDir") {
              if (element[4] == "ocupacion") {
                let index = this.configuracion.data.auxiliares[
                  element[4]
                ][1].findIndex((dato) => {
                  return this.remitente[element[4]] == dato[0];
                });
                if (index > -1) {
                  aux2 = this.t(
                    `variables.${element[4]}.${
                      this.configuracion.data.auxiliares[element[4]][1][
                        index
                      ][0]
                    }`
                  );
                }
              } else if (element[4] == "nacionalidad") {
                let index = this.configuracion.data.auxiliares[
                  element[4]
                ][0].findIndex((dato) => {
                  return this.remitente[element[4]] == dato[0];
                });
                if (index > -1) {
                  aux2 = this.t(
                    `variables.${element[4]}.${
                      this.configuracion.data.auxiliares[element[4]][0][
                        index
                      ][0]
                    }`
                  );
                }
              } else if (element[4] == "origen_fondos") {
                let index = this.configuracion.data.auxiliares[
                  element[4]
                ][0].findIndex((dato) => {
                  return this.remitente[element[4]] == dato[0];
                });
                if (index > -1) {
                  aux2 = this.t(
                    `variables.${element[4]}.${
                      this.configuracion.data.auxiliares[element[4]][0][
                        index
                      ][0]
                    }`
                  );
                }
              } else {
                let index = this.configuracion.data.auxiliares[
                  element[4]
                ][0].findIndex((dato) => {
                  return this.remitente[element[4]] == dato[0];
                });
                if (index > -1) {
                  aux2 =
                    this.configuracion.data.auxiliares[element[4]][0][index][1];
                }
              }
            } else if (element[4] == "residencia") {
              let index = this.configuracion.pais["152_1"].residencia.findIndex(
                (dato) => {
                  return this.remitente[element[4]] == dato[0];
                }
              );
              if (index > -1) {
                aux2 = this.configuracion.pais["152_1"].residencia[index][1];
              }
            } else if (element[4] == "region") {
              aux2 = this.remitente.region_nombre;
            } else if (element[4] == "comuna_id") {
              aux2 = this.remitente.comuna_nombre;
            }
            aux.push([element[4], aux2]);
          }
        });
      }
      // console.log(this.remitente);
      return aux;
    },
  },
  methods: {
    ...mapMutations(["updateProcesoGeneral", "set_nav_stack"]),

    backLocal() {
      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: false });
      this.$router.push({ name: destino, replace: true });
    },
    callUpdateRemitenteForm() {
      this.$router.push({ name: "registroFormEdit", replace: true });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_misDatos = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  mounted() {},
  ionViewWillEnter() {
    // console.log(this.configuracion);
    this.updateProcesoGeneral("misDatos");
  },
  ionViewDidEnter() {
    // this.header = this.$refs["myHeader_misDatos"].offsetHeight;
    // this.footer = this.$refs["footer_misDatos"].offsetHeight;
    // this.cont_visible = true;
    // setTimeout(() => {
    //   this.header = this.$refs["myHeader_misDatos"].offsetHeight;
    //   this.footer = this.$refs["footer_misDatos"].offsetHeight;
    //   this.cont_visible = true;
    // }, 100);

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_misDatos"].offsetHeight;
        this.footer = this.$refs["footer_misDatos"].offsetHeight;
        this.cont_visible = true;
        this.contenido_misDatos = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_misDatos = blur_sup_style(this.header);
        this.blur_inf_misDatos = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_misDatos"].scrollHeight >
          this.$refs["contenido_misDatos"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewDidLeave() {},
};
</script>

<style>
.misDatosTitle {
  padding-bottom: 1rem;

  text-align: center;
  color: #801b1c;
}
.misDatosBody {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.misDatosRow {
  margin-top: 0.9375rem;
}
.misDatosItem {
  /* font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 13px;
letter-spacing: 1px; */
  text-transform: uppercase;
  color: #4c4c4c;
}
.misDatosItemUno {
  display: flex;
  justify-content: space-between;
}
.misDatosItemUnoText {
  /* font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 11px;
line-height: 13px; */
  display: flex;
  align-items: flex-end;
  /* letter-spacing: 0.857033px; */
  text-decoration-line: underline;
  text-transform: none;
  color: #614ad3;
  padding: 0.2rem 0.3rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.misDatosData {
  /* margin-top: 0.3125rem; */
  /* font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 11px;
line-height: 13px;
letter-spacing: 0.5px; */
  color: #787878;
}
.misDatos_footer {
  min-height: 2rem;
}
</style>